import React, {useEffect, useState} from "react"
import {
    getUsers,
    deleteUsers,
    editUser,
    newUser,
    getUserRooms,
    addUsersRoomsRel,
    getUsersRoomsRel
} from "./data/rooms-server"
import MyTable from "./components/my-table"
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {MeetingRoom} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const MyUsers = (props) => {
    const startSortBy = [{id: "id", desc: false}]
    const sortByJson = localStorage.getItem("sortByUsers")
    const defaultSortBy = sortByJson !== null ? JSON.parse(sortByJson) : startSortBy
    const [sortBy, setSortBy] = useState(defaultSortBy)

    const startPaginationParams = {pageSize: 5, pageIndex: 0}
    const paginationParamsJson = localStorage.getItem("paginationParamsUsers")
    const defaultPaginationParams = paginationParamsJson !== null ? JSON.parse(paginationParamsJson) : startPaginationParams
    const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
    const [filterValue, setFilterValue] = useState('')
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [users, setUsers] = useState([])
    const [usersCount, setUsersCount] = useState(0)
    const initialDialogText = "successfully saved"

    const fetchUsers = (sortBy, paginationParams, filterValue) => {
        setSkipPageReset(true)
        let sb = sortBy
        if (sb[0] === undefined) {
            if (defaultSortBy[0] !== undefined) {
                sb = defaultSortBy
            } else {
                sb = startSortBy
            }
        }
        if (paginationParams === undefined) {
            if (defaultPaginationParams !== undefined) {
                paginationParams = defaultPaginationParams
            } else {
                paginationParams = startPaginationParams
            }
        }
        return getUsers(sb[0].id, sb[0].desc ? "desc" : "asc", paginationParams, filterValue)
    }

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true
        Promise.all([
            fetchUsers(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            if (isSubscribed) {
                setUsersCount(response[0].count)
                setUsers(customizeFields(response[0].data))
            }
        }).catch(ex => console.error(ex))
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [paginationParams, sortBy, filterValue])// eslint-disable-line react-hooks/exhaustive-deps

    const customizeFields = (data) => {
        return data.map(p => {
                if (p.enabled) {
                    p = {...p, enabled: true}
                } else {
                    p = {...p, enabled: false}
                }
                if (p.levelDescription.toLowerCase() === 'operator') {
                    p = {...p, levelDescription: <LevelCell row={p}/>}
                }
                return p
            }
        )
    }

    const LevelCell = (props) => {
        const {row} = props
        const [open, setOpen] = React.useState(false)
        const [rooms, setRooms] = React.useState([])
        //const [scroll, setScroll] = React.useState('paper')
        const [checked, setChecked] = React.useState([])
        const scroll = 'paper'
        const handleClose = () => {
            setOpen(false)
        };
        React.useEffect(() => {
            const ac = new AbortController()
            let isSubscribed = true
            Promise.all([
                getUserRooms()
            ]).then(response => {
                if (isSubscribed) {
                    setRooms(response[0])

                }
            }).catch(ex => console.error(ex))
            return () => {
                ac.abort()
                isSubscribed = false
            }
        }, [])// eslint-disable-line react-hooks/exhaustive-deps
        const handleClickOpen = (id) => {
            getUsersRoomsRel(id).then(response => {
                let rooms = []
                response.forEach((item, index, array) => {
                    rooms.push(item.idRoom)
                })
                setChecked(rooms)
                setOpen(true)
            })

        }

        const handleSave = (id) => {
            console.log(id);
            console.log(checked);
            addUsersRoomsRel(id, checked).then(
                r => {
                    setOpen(false)
                    setChecked([])
                }
            )
        }

        const handleToggle = (idRoom) => {
            const currentIndex = checked.indexOf(idRoom)
            const newChecked = [...checked]
            if (currentIndex === -1) {
                newChecked.push(idRoom)
            } else {
                newChecked.splice(currentIndex, 1)
            }
            setChecked(newChecked);
        }
        return (
            <div>
                {row.levelDescription}
                <Tooltip title={"assign rooms"}>
                    <IconButton aria-label={"assign"} onClick={() => handleClickOpen(row.id)}>
                        {<MeetingRoom/>}
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Assign rooms</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>

                        <List>
                            {
                                rooms.map((room) => {
                                    const labelId = `checkbox-list-label-${room.id}`;
                                    return (
                                        <ListItem key={room.id} role={undefined} dense button
                                                  onClick={() => handleToggle(room.id)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(room.id) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={room.id + ' ' + room.name}/>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleSave(row.id)} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }


    const onChangeSort = (sortBy) => {
        setSortBy(sortBy)
        const sortByJson = JSON.stringify(sortBy)
        localStorage.setItem("sortByUsers", sortByJson)
    }

    const onChangePaginationParams = (paginationParams) => {
        setPaginationParams(paginationParams)
        const paginationParamsJson = JSON.stringify(paginationParams)
        localStorage.setItem("paginationParamsUsers", paginationParamsJson)
    }

    const filter = (value) => {
        if (value !== undefined && value.length > 2) {
            setFilterValue(value)
        } else {
            setFilterValue('')
        }

    }
    const onModifyTableContent = () => {
        Promise.all([
            fetchUsers(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            setUsersCount(response[0].count)
            setUsers(customizeFields(response[0].data))
        }).catch(ex => console.error(ex))
    }

    const addUserHandler = async (user, mode) => {
        let result = {}
        if (mode === "add") {
            await newUser(user).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: false}
                } else if (r[0] !== null && r[0].result === "np") {
                    result = {text: "save failed, please chose a new password", open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        } else {
            await editUser(user).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: false}
                } else if (r[0] !== null && r[0].result === "np") {
                    result = {text: "save failed, please chose a new password", open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        }
        console.log(result)
        return result
    }

    const deleteUsersHandler = (users) => {
        let result = {}
        deleteUsers(users).then(r => {
            if (r[0] !== null && r[0].result === "ok") {
                onModifyTableContent()
                result = {text: initialDialogText, open: true}
                //setDialog({text: initialDialogText, open: true})
            } else {
                result = {text: "save failed, please try again later", open: true}
                //setDialog({text: "save failed, please try again later", open: true})
            }
        })
        return result
    }


    const columns = React.useMemo(() => [
        {
            Header: "id",
            accessor: "id"
        },
        {
            Header: "Username",
            accessor: "username"
        },
        {
            Header: "Password",
            accessor: "password"
        },
        {
            Header: "Descrizione",
            accessor: "description"
        },
        {
            Header: "Livello",
            accessor: "levelDescription"
        },
        {
            Header: "Attivo",
            accessor: "enabled",
        },
        {
            Header: "Creazione",
            accessor: "creationDate"
        }
    ], [])
    return (
        <div>
            <MyTable
                columns={columns}
                data={users}
                initialSortBy={defaultSortBy}
                onModifyTableContent={onModifyTableContent}
                onChangeSort={onChangeSort}
                skipPageReset={skipPageReset}
                onChangePaginationParams={onChangePaginationParams}
                initialPaginationParams={defaultPaginationParams}
                recordsCount={usersCount}
                addHandler={addUserHandler}
                deleteHandler={deleteUsersHandler}
                tableName="users"
                tableTitle="Users"
                filter={filter}
            />
        </div>
    )
}
export default MyUsers
