import React, {useEffect, useState} from "react";
import {getDevices, editDevice,deleteDevices} from "./data/rooms-server"
import MyTable from "./components/my-table"

const MyDevices = (props) => {
    const startSortBy = [{id: "id", desc: false}]
    const sortByJson = localStorage.getItem("sortByDevices")
    const defaultSortBy = sortByJson !== null ? JSON.parse(sortByJson) : startSortBy
    const [sortBy, setSortBy] = useState(defaultSortBy)

    const startPaginationParams = {pageSize: 5, pageIndex: 0}
    const paginationParamsJson = localStorage.getItem("paginationParamsDevices")
    const defaultPaginationParams = paginationParamsJson !== null ? JSON.parse(paginationParamsJson) : startPaginationParams
    const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
    const [filterValue,setFilterValue] = useState('')
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [devices, setDevices] = useState([])
    const [devicesCount, setDevicesCount] = useState(0)
    const initialDialogText = "successfully saved"

    const fetchDevices = (sortBy, paginationParams, filterValue) => {
        setSkipPageReset(true)
        let sb = sortBy
        if (sb[0] === undefined) {
            if (defaultSortBy[0] !== undefined) {
                sb = defaultSortBy
            } else {
                sb = startSortBy
            }
        }
        if (paginationParams === undefined) {
            if (defaultPaginationParams !== undefined) {
                paginationParams = defaultPaginationParams
            } else {
                paginationParams = startPaginationParams
            }
        }
        return getDevices(sb[0].id, sb[0].desc ? "desc" : "asc", paginationParams, filterValue)
    }

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true
        Promise.all([
            fetchDevices(sortBy, paginationParams,filterValue)
        ]).then((response) => {
            if (isSubscribed) {
                setDevicesCount(response[0].count)
                setDevices(customizeFields(response[0].data))
            }
        }).catch(ex => console.error(ex))
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [paginationParams, sortBy,filterValue])// eslint-disable-line react-hooks/exhaustive-deps

    const customizeFields = (data) => {
        return data.map(p =>
            {
                if(p.enabled){
                    return { ...p,enabled: true }
                }else{
                    return { ...p,enabled: false }
                }
            }
        )
    }

    const onChangeSort = (sortBy) => {
        setSortBy(sortBy)
        const sortByJson = JSON.stringify(sortBy)
        localStorage.setItem("sortByDevices", sortByJson)
    }

    const onChangePaginationParams = (paginationParams) => {
        setPaginationParams(paginationParams)
        const paginationParamsJson = JSON.stringify(paginationParams)
        localStorage.setItem("paginationParamsDevices", paginationParamsJson)
    }

    const filter = (value) => {
        if (value !== undefined && value.length > 2){
            setFilterValue(value)
        }else{
            setFilterValue('')
        }

    }
    const onModifyTableContent = () => {
        Promise.all([
            fetchDevices(sortBy, paginationParams,filterValue)
        ]).then((response) => {
            setDevicesCount(response[0].count)
            setDevices(customizeFields(response[0].data))
        }).catch(ex => console.error(ex))
    }

    const addDeviceHandler = (device, mode) => {
        let result = {}
        if (mode === "add") {
            /*newUser(user).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })*/
        } else {
            editDevice(device).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        }
        return result
    }

    const deleteDevicesHandler = (devices) => {
        let result = {}
        deleteDevices(devices).then(r => {
            if (r[0] !== null && r[0].result === "ok") {
                onModifyTableContent()
                result = {text: initialDialogText, open: true}
            } else {
                result = {text: "save failed, please try again later", open: true}
            }
        })
        return result
    }


    const columns = React.useMemo(() => [
        {
            Header: "id",
            accessor: "id"
        },
        {
            Header: "Descrizione",
            accessor: "description"
        },
        {
            Header: "Utente",
            accessor: "userDescription"
        }
    ], [])
    return (
        <div>
            <MyTable
                columns={columns}
                data={devices}
                initialSortBy={defaultSortBy}
                onModifyTableContent={onModifyTableContent}
                onChangeSort={onChangeSort}
                skipPageReset={skipPageReset}
                onChangePaginationParams={onChangePaginationParams}
                initialPaginationParams={defaultPaginationParams}
                recordsCount={devicesCount}
                addHandler={addDeviceHandler}
                deleteHandler={deleteDevicesHandler}
                tableName="devices"
                tableTitle="Devices"
                filter={filter}
            />
        </div>
    )
}

export default MyDevices
