import React, { PureComponent } from 'react'
import { Page, Section } from 'react-page-layout'
import MyCalendar from "./my-calendar"
import MyRooms from "./my-rooms"
import MyUsers from "./my-users"
import MyDevices from "./my-devices";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

class MyPage extends PureComponent {



    constructor(props) {
        super(props);
        const level = localStorage.getItem('level')
        this.state = {
            layout: 'private',
            title: 'ROOMS - Calendario prenotazioni',
            contentSectionName: 'content',
            currentContent: level === '1' ? 'users' : 'calendar',
        };
    }



    menuHandler = (e,page) =>{
        e.preventDefault()
        this.setState({currentContent: page})
    }

    renderSidebar() {
        const currentContent = this.state.currentContent
        const level = localStorage.getItem('level')
        console.log(level)
        return (
            <Section slot="sidebar" >
                <MenuList>
                    {level === '2' || level === '3' ?
                        <MenuItem style={currentContent === 'calendar' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'calendar')}>
                            Calendar
                        </MenuItem>
                        :<div></div>
                    }
                    {level === '2' ?
                        <MenuItem style={currentContent === 'rooms' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'rooms')}>
                            Rooms
                        </MenuItem>
                        :<div></div>
                    }
                    {level === '1' || level === '2' ?
                        <MenuItem style={currentContent === 'users' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'users')}>
                            Users
                        </MenuItem>
                        : <div></div>
                    }
                    {level === '1' ?
                        <MenuItem style={currentContent === 'devices' ? {color: "white"} : {color: "#aeb2b7"}}
                                  onClick={(e) => this.menuHandler(e, 'devices')}>
                            Devices
                        </MenuItem>
                        : <div></div>
                    }
                </MenuList>
            </Section>
        )
    }

    renderFooter() {
        return (
            <Section slot="footer">
                Footer content
            </Section>
        )
    }

    render() {
        const { layout, title, contentSectionName, currentContent} = this.state

        return (
            <Page layout={layout} title={title}>
                {this.renderSidebar()}
                <Section slot={contentSectionName}>
                    {currentContent === 'calendar' && <MyCalendar/>}
                    {currentContent === 'rooms' && <MyRooms/>}
                    {currentContent === 'users' && <MyUsers/>}
                    {currentContent === 'devices' && <MyDevices/>}
                </Section>
                {this.renderFooter()}
            </Page>
        )
    }
}

export default MyPage
