import React, {Suspense, useState} from 'react'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {lighten} from "@material-ui/core";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete'

const useToolbarStyles = makeStyles(theme => ({
    root: {
        //border: '1px solid black',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            }
}))

const loadComponent = name => {
    const Component = React.lazy(() =>
        import(`../components/${name}-dialog`)
    );
    return Component;
}


const TableToolbar = props => {

    const [filterInput, setFilterInput] = useState("");
    const classes = useToolbarStyles()
    const {
        filterHandler,
        addHandler,
        numSelected,
        deleteHandler,
        selectedFlatRows,
        tableName,
        title
    } = props

    const Component = loadComponent(tableName);

    const handleFilterChange = e => {
        const value = e.target.value || undefined
        setFilterInput(value)
        filterHandler(value)
    }

    return (
        <Toolbar className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
        })}>
            {numSelected === 0 ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <Component addHandler={addHandler} mode="add" selectedFlatRows={[]}/>
                </Suspense>) : (null)
            }
            {numSelected > 0 ? (
                <Typography
                    //className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <div style={{width: '100%' }}>
                    <div style={{float: 'left' }}>
                        <Typography variant="h6" id="tableTitle">
                            {title}
                        </Typography>
                    </div>

                    <div  style={{float: 'right'}}>
                        <input
                            placeholder={"Search..."}
                            value={filterInput || ''}
                            onChange={handleFilterChange}
                        />
                    </div>

                </div>

            )}
            {numSelected === 1 ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <Component addHandler={addHandler} mode="edit" selectedFlatRows={selectedFlatRows}/>
                </Suspense>
            ) : null}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={deleteHandler}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            ) : null}
        </Toolbar>
    );


}

TableToolbar.propTypes = {
    filterHandler: PropTypes.func.isRequired,
    addHandler: PropTypes.func.isRequired,
    numSelected: PropTypes.number.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    selectedFlatRows: PropTypes.array.isRequired,
    tableName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default TableToolbar
