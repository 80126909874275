import React, {useEffect, useState} from "react"
import getRooms, {deleteRooms, editRoom, newRoom} from "./data/rooms-server"
import MyTable from "./components/my-table"


const MyRooms = (props) => {
    const startSortBy = [{id: "id", desc: false}]
    const sortByJson = localStorage.getItem("sortByRooms")
    const defaultSortBy = sortByJson !== null ? JSON.parse(sortByJson) : startSortBy
    const [sortBy, setSortBy] = useState(defaultSortBy)

    const startPaginationParams = {pageSize: 5, pageIndex: 0}
    const paginationParamsJson = localStorage.getItem("paginationParamsRooms")
    const defaultPaginationParams = paginationParamsJson !== null ? JSON.parse(paginationParamsJson) : startPaginationParams
    const [paginationParams, setPaginationParams] = useState(defaultPaginationParams)
    const [filterValue,setFilterValue] = useState('')
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [rooms, setRooms] = useState([])
    const [roomsCount, setRoomsCount] = useState(0)
    const initialDialogText = "successfully saved"

    const fetchRooms = (sortBy, paginationParams, filterValue) => {
        setSkipPageReset(true)
        let sb = sortBy
        if (sb[0] === undefined) {
            if (defaultSortBy[0] !== undefined) {
                sb = defaultSortBy
            } else {
                sb = startSortBy
            }
        }
        if (paginationParams === undefined) {
            if (defaultPaginationParams !== undefined) {
                paginationParams = defaultPaginationParams
            } else {
                paginationParams = startPaginationParams
            }
        }
        return getRooms(sb[0].id, sb[0].desc ? "desc" : "asc", paginationParams, filterValue)
    }

    useEffect(() => {
        const ac = new AbortController()
        let isSubscribed = true
        Promise.all([
            fetchRooms(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            if (isSubscribed) {
                setRoomsCount(response[0].count)
                setRooms(customizeFields(response[0].data))
            }
        }).catch(ex => console.error(ex))
        return () => {
            ac.abort()
            isSubscribed = false
        }
    }, [paginationParams, sortBy,filterValue])// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSort = (sortBy) => {
        setSortBy(sortBy)
        const sortByJson = JSON.stringify(sortBy)
        localStorage.setItem("sortByRooms", sortByJson)
    }

    const onChangePaginationParams = (paginationParams) => {
        setPaginationParams(paginationParams)
        const paginationParamsJson = JSON.stringify(paginationParams)
        localStorage.setItem("paginationParamsRooms", paginationParamsJson)
    }

    const onModifyTableContent = () => {
        Promise.all([
            fetchRooms(sortBy, paginationParams, filterValue)
        ]).then((response) => {
            setRoomsCount(response[0].count)
            setRooms(customizeFields(response[0].data))
        }).catch(ex => console.error(ex))
    }

    const addRoomHandler = (room, mode) => {
        let result = {}
        if(room.capacity === ''){
            room.capacity = 0
        }
        if(room.priority === ''){
            room.priority = 0
        }
        if (mode === "add") {
            newRoom(room).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        } else {
            editRoom(room).then(r => {
                if (r[0] !== null && r[0].result === "ok") {
                    onModifyTableContent()
                    result = {text: initialDialogText, open: true}
                } else {
                    result = {text: "save failed, please try again later", open: true}
                }
            })
        }
        return result
    }

    const deleteRoomHandler = (rooms) => {
        let result = {}
        deleteRooms(rooms).then(r => {
            if (r[0] !== null && r[0].result === "ok") {
                onModifyTableContent()
                result = {text: initialDialogText, open: true}
                //setDialog({text: initialDialogText, open: true})
            } else {
                result = {text: "save failed, please try again later", open: true}
                //setDialog({text: "save failed, please try again later", open: true})
            }
        })
        return result
    }

    const filter = (value) => {
        if (value !== undefined && value.length > 1){
            setFilterValue(value)
        }else{
            setFilterValue('')
        }

    }

    const customizeFields = (data) => {
        return data.map(p => {
                if (p.capacity === 0) {
                    p = {...p, capacity: ''}
                }
            if (p.group !== '') {
                p = {...p, group: p.group+' (priority '+p.priority+')'}
            }
                return p
            }
        )
    }

    const columns = React.useMemo(() => [
        {
            Header: "id",
            accessor: "id"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Price",
            accessor: "price"
        },

        {
            Header: "Capacity",
            accessor: "capacity"
        },
        {
            Header: "Group",
            accessor: "group"
        },
        {
            Header: "Device",
            accessor: "idDevice"
        },
        {
            Header: "Calendar",
            accessor: "calendar"
        }
    ], [])
    return (
        <div>
            <MyTable
                columns={columns}
                data={rooms}
                initialSortBy={defaultSortBy}
                onModifyTableContent={onModifyTableContent}
                onChangeSort={onChangeSort}
                skipPageReset={skipPageReset}
                onChangePaginationParams={onChangePaginationParams}
                initialPaginationParams={defaultPaginationParams}
                recordsCount={roomsCount}
                addHandler={addRoomHandler}
                deleteHandler={deleteRoomHandler}
                tableName="rooms"
                tableTitle="Rooms"
                filter={filter}
            />
        </div>
    )
}

export default MyRooms
