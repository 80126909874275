import axios from 'axios';
import * as Constants from '../constants'


const getDevices = async (sort, order,paginationParams, filterValue) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "devices",
        sort: sort,
        order: order,
        pageIndex: paginationParams.pageIndex,
        pageSize: paginationParams.pageSize,
        filterValue: filterValue
    });
    return result.data;
}

const editDevice = async (device) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "edit_device",
        device: device
    });
    return result.data;
}

const deleteDevices = async (devices) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_devices",
        devices: devices
    });
    return result.data;
}

const getAllUsers = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "all_users",
    });
    return result.data;
}

const getRoomsGroups = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "rooms_groups",
    });
    return result.data;
}

const getRooms = async (sort, order,paginationParams, filterValue) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "rooms",
        sort: sort,
        order: order,
        pageIndex: paginationParams.pageIndex,
        pageSize: paginationParams.pageSize,
        filterValue: filterValue
    });
    return result.data;
}

const getAllRooms = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "all_rooms",
    });
    return result.data;
}

const getUserRooms = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "user_rooms",
    });
    return result.data;
}

const newRoom = async (room) => {

    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "save_room",
        room: room
    });

    return result.data;
}

const editRoom = async (room) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "edit_room",
        room: room
    });
    return result.data;
}

const saveEvent = async (event) => {
    let data = new FormData()
    data.append('token',localStorage.getItem('token'))
    data.append('mode',"save_event")
    data.append('startDate',event.startDate.getTime())
    data.append('endDate',event.endDate.getTime())
    data.append('title',event.title)
    data.append('company',event.company)
    data.append('idRoom',event.idRoom)
    data.append('file',event.file)
    let result = await axios.post(Constants.APIURL, data);
    return result.data;
}

const deleteEvent = async (id) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_event",
        id: id
    });
    return result.data;
}


const addUsersRoomsRel = async (idUser,rooms) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "add_users_rooms_rel",
        idUser: idUser,
        rooms: rooms
    });
    return result.data;
}

const getUsersRoomsRel = async (idUser) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "get_users_rooms_rel",
        idUser: idUser
    });
    return result.data;
}

const deleteRooms = async (rooms) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_rooms",
        rooms: rooms
    });
    return result.data;
}

const getEvents = async () => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "all_events",
    });
    return result.data;
}

const login = async (user) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : '',
        mode: "login",
        username: user.username,
        password: user.password
    });
    return result.data;
}

const getUsers = async (sort, order,paginationParams,filterValue) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "users",
        sort: sort,
        order: order,
        pageIndex: paginationParams.pageIndex,
        pageSize: paginationParams.pageSize,
        filterValue: filterValue
    });
    return result.data
}
const deleteUsers = async (users) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "delete_users",
        users: users
    });
    return result.data;
}

const editUser = async (user) => {
    console.log(user)
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "edit_user",
        user: user
    });
    return result.data;
}

const newUser = async (user) => {
    let result = await axios.post(Constants.APIURL, {
        token: localStorage.getItem('token'),
        mode: "save_user",
        user: user
    });
    return result.data;
}

export default getRooms;
export {
    editRoom,
    deleteRooms,
    newRoom,
    getAllRooms,
    saveEvent,
    deleteEvent,
    getEvents,
    login,
    getUsers,
    deleteUsers,
    editUser,
    newUser,
    getDevices,
    editDevice,
    deleteDevices,
    getAllUsers,
    getUserRooms,
    addUsersRoomsRel,
    getUsersRoomsRel,
    getRoomsGroups
}
