import React from 'react';
//import PropTypes from 'prop-types';
import { Slot } from  'react-page-layout';
//import localStorage from 'local-storage'
import ReactDOM from "react-dom";
import App from "../App";

function PrivateLayout(props) {
    const { title } = props;
    const username = localStorage.getItem('username')
    const level = localStorage.getItem('level')
    let levelDescription = ''
    switch (level){
        case '1':
            levelDescription = 'admin'
            break
        case '2':
            levelDescription = 'client'
            break
        case '3':
            levelDescription = 'operator'
            break
        default:
    }

    const logout = () => {
        localStorage.setItem('token', '')
        localStorage.setItem('level', '0')
        localStorage.setItem('username', '')
        ReactDOM.render(<App mode={"login"} />, document.getElementById('root'));
    }

    return (
        <div className="layout">
            <Slot name="header" className="layout-header" component="header">
                <div className="layout-header-left"><h1>{title || 'Unnamed'}</h1></div><div className="layout-header-right">({levelDescription})&nbsp;{username}&nbsp;&nbsp;<span onClick={logout}>logout</span></div>
            </Slot>
            <hr />
            <Slot name="main" className="layout-main" component="main">
                <Slot name="sidebar" className="layout-sidebar" />
                <Slot name="content-wrapper" className="layout-content-wrapper">
                    <Slot name="content" className="layout-content" />
                </Slot>
            </Slot>
            <Slot name="footer" className="layout-footer">
                This footer is defined on the <strong>Layout</strong>
            </Slot>
        </div>
    );
}

PrivateLayout.propTypes = {
    //title: PropTypes.string.isRequired,
};

export default PrivateLayout;
