import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import React, {useEffect, useState} from "react";
import moment from "moment";
import '../css/mycalendar.css';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {deleteEvent, getAllRooms, getEvents, saveEvent} from "./data/rooms-server";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import * as Constants from "./constants";
import Loader from "react-loader-spinner";


let MyCalendar = (props) => {
    const localizer = momentLocalizer(moment);
    const [eventList, setEventList] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [company, setCompany] = useState("");
    const [period, setPeriod] = useState({});
    const [selectedEvent, setSelectedEvent] = useState({});
    const [selectedRoom, setSelectedRoom] = useState({id: 0});
    const [rooms, setRooms] = useState([]);
    const [file, setFile] = useState("");
    const [saving, setSaving] = useState(false);

    const fetchRooms = async () => {
        setRooms(await getAllRooms());
    }

    const fetchEvents = async () => {
        const eventsFromServer = await getEvents()
        const events = []
        eventsFromServer.forEach(event => {
            events.push({
                start: new Date(event.startDate),
                end: new Date(event.endDate),
                title: "Event: " + event.title + " | Company: " + event.cp + " | Room: " + event.room +
                    (event.logoName !== '' && event.logoName !== undefined ? " | Logo: " + event.logoName : ""),
                id: event.id
            })
        })
        setEventList(events)
    }

    useEffect(() => {
        fetchRooms()
        fetchEvents()
    }, []);

    const handleSelect = ({start, end}) => {
        setDialogOpen(true);
        setPeriod({start, end});

    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle("");
        setCompany("");
    }

    const handleOkDialog = () => {
        setSaving(true)
        //setDialogOpen(false);
        if (title.trim() !== "" && selectedRoom.id !== 0) {
            saveEvent({
                startDate: period.start,
                endDate: period.end,
                title: title,
                company: company,
                idRoom: selectedRoom.id,
                file: file
            }).then(r => {
                setSaving(false)
                setDialogOpen(false)
                if (r.last !== -1) {
                    setEventList([
                        ...eventList,
                        {
                            ...period,
                            title: "Event: " + title + " | Company: " + company + " | Room: " + selectedRoom.name
                                + (file.name !== undefined ? " | Logo: " + file.name : ""),
                            id: r.last
                        }
                    ])
                } else {
                    setAlertDialogOpen(true);
                }
            })

        }
        setTitle("")
        setCompany("")
        setSelectedRoom({id: 0})
        setFile("")
    }

    const handleChangeTitle = ({target: {value}}) => {
        setTitle(value);
    }

    const handleChangeCompany = ({target: {value}}) => {
        setCompany(value);
    }

    const onSelectEvent = e => {
        setSelectedEvent(e);
        setSelectedRoom({id: 0});
        setDeleteDialogOpen(true);
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedRoom({id: 0});

    }

    const handleAlertDeleteDialog = () => {
        setAlertDialogOpen(false);
    }

    const handleDeleteDialog = () => {
        setSaving(true)
        deleteEvent(selectedEvent.id).then(r => {
            setSaving(false)
            setEventList(eventList.filter(ev => ev.title !== selectedEvent.title));
            setDeleteDialogOpen(false);
        })
    }

    const handleRoomsChange = ({target: {value}}) => {
        const selected = rooms.filter((r) => r.id === value)[0];
        //console.log(selected);
        setSelectedRoom(selected);
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }


    const renderEventDescription = etitle => {
        let event = ""
        let company = ""
        let room = ""
        let logoName = ""
        if (etitle !== undefined) {
            const elements = etitle.split(" | ")
            event = elements[0].replace("Event: ", "")
            company = elements[1].replace("Company: ", "")
            room = elements[2].replace("Room: ", "")
            logoName = elements[3] !== undefined ? elements[3].replace("Logo: ", "") : ''
        }
        return (
            <DialogContentText id="alert-dialog-description">
                <b>Event: </b>{event}<br/>
                <b>Company: </b>{company}<br/>
                <b>Room: </b>{room}<br/>
                {logoName !== '' && <Logo logoName={logoName} company={company} event={event}/>}
            </DialogContentText>
        )
    }


    return (

        <div style={{height: '100vh'}}>

            <Dialog
                open={alertDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    Impossibile salvare l'evento poichè sovrapposto ad un altro nella stessa sala.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertDeleteDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {saving ? <Loader
                        type="Bars"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={3000} //3 secs
                    /> :renderEventDescription(selectedEvent.title)}
                </DialogContent>
                {!saving && <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
                        Close
                    </Button>
                    <Button onClick={handleDeleteDialog} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>}
            </Dialog>
            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {saving ? <Loader
                            type="Bars"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={3000} //3 secs
                        /> :
                        <div>
                            <DialogContentText id="alert-dialog-description">
                                Insert new event.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Title"
                                type="text"
                                fullWidth
                                value={title}
                                onChange={handleChangeTitle}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Company"
                                type="text"
                                fullWidth
                                value={company}
                                onChange={handleChangeCompany}
                            />
                            <InputLabel id="buildingsLabel">Rooms</InputLabel>
                            <Select fullWidth defaultValue="" label="buildingsLabel"
                                    value={selectedRoom != null ? selectedRoom.id : 0}
                                    onChange={handleRoomsChange}>
                                <MenuItem value={0} key={0}></MenuItem>
                                {
                                    rooms.map((dt, i) => {
                                        return (
                                            <MenuItem value={dt.id} key={dt.id}>{dt.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <Box m={2}/>
                            <InputLabel id="logoLabel">Select logo (max 500x500 pixel)...</InputLabel>
                            <input type="file" accept="image/*" name="upload_file" onChange={handleFileChange}/>
                        </div>
                    }
                </DialogContent>

                {!saving && <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleOkDialog} color="primary" autoFocus>
                        Ok
                    </Button>

                </DialogActions>}
            </Dialog>
            <Calendar
                selectable
                defaultView={Views.WEEK}
                localizer={localizer}
                events={eventList}
                startAccessor="start"
                endAccessor="end"
                step={30}
                showMultiDayTimes
                onSelectSlot={handleSelect}
                onSelectEvent={onSelectEvent}
            />

        </div>

    );

}

const Logo = (props) => {
    const {logoName, company, event} = props
    return (
        <span>
            <b>Logo: </b>{logoName}<br/><br/>

            <img className="logoImg" alt="logo"
                 src={Constants.APIURL + "?mode=get_logo&logoName=" + logoName + "&company=" + company + "&event=" + event}/>
        </span>
    );
}

export default MyCalendar
